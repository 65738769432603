import React from "react";
import { ThemeProvider } from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from 'sections/Navigation';
import Quote from 'sections/Inquiry';
import Footer from 'sections/Footer-classic';

import { GlobalStyle } from 'sections/app.style';
import theme from 'theme/app/themeStyles';

const IndexPage = () => (
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Layout>
            <SEO title="MyCorp IO" />
            <Navigation />
            <Quote />
            <Footer />
        </Layout>
    </ThemeProvider>
)

export default IndexPage
