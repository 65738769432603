import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from "reusecore/Layout";
import VintageBox from "reusecore/VintageBox";
import Button from "reusecore/Button"; 
import TextBox from "reusecore/Textbox"; 
import QuoteWrapper from "./quote.style"; 

const Quote = () => {

    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [inquiry, setInquiry] = useState("")

    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [inquirySent, setInquirySent] = useState(false); 

    useEffect(() => {
    }, []);

    useEffect(() => {

        setErrorMsg(null);
        setErrorShow(false);
 
        if (!title || title.trim().length <= 0) {
            setErrorMsg("Title is not valid");
            return;
        }  
        if (!inquiry || inquiry.trim().length <= 0) {
            setErrorMsg("Inquiry is not valid");
            return;
        }  
        if (!email || email.trim().length <= 0) {
            setErrorMsg("Email is not valid");
            return;
        }  

        setValid(true);
    }, [email, title, inquiry]);

    // send inquiry emails to both MyCorp sales and requester
    const send = async () => {

        // subaccount in mandrill
        const subAccount = 'MyCorp';

        // destination email address for mycorp inquiry
        const toMyCorp = 'sales@cyder.com.sg';

        // mandrill template for mycorp sales
        const templateMyCorp = 'mycorp-inquiry';

        // mandrill template for response to requester
        const templateResponse = 'mycorp-inquiry-response';

        setInquirySent(false);
        setLoading(true);
        setErrorMsg("");
        setErrorShow(false);

        const postBodyToMyCorp = {
            to: toMyCorp,
            subAccount,
            template: templateMyCorp,
            params: [ 
                {
                    name: 'email',
                    content: email,
                },
                {
                    name: 'subject',
                    content: title,
                }, 
                {
                    name: 'inquiry',
                    content: inquiry,
                },
            ]
        };

        const postBodyToResponse = {
            to: email,
            subAccount,
            template: templateResponse,
            params: [
                {
                    name: 'name',
                    content: 'There',
                },
            ]
        };

        // send email to mycorp sales
        await axios.post('https://notify.api.mycorp.io/api/notify/mandrill', postBodyToMyCorp);

        // send response email to requster
        await axios.post('https://notify.api.mycorp.io/api/notify/mandrill', postBodyToResponse);

        setLoading(false);
        setInquirySent(true);

        window.scrollTo(0, 0);
    }


    return (
        <QuoteWrapper>
            <div className="single-post-wrapper">
                <Container>
                    {inquirySent ?
                        <Row>
                            <Col xs={12}>
                                <div className="comment-box-wrapper">
                                    <h2 className="block-title">Let us know your thought</h2>
                                    <p>
                                        Thank you for your inquiry.
                                        </p>
                                </div>
                            </Col>
                        </Row>
                        :

                        <Row>
                            <Col xs={12}>
                                <div className="comment-box-wrapper">
                                    <h2 className="block-title">Let us know your thought</h2>
                                    <p>
                                    and we will get back to you.
                                    </p>
                                    <Row>
                                        <Col xs={12}>
                                            <TextBox
                                                value={title}
                                                onValueChange={setTitle}
                                                type="text"
                                                name="title"
                                                label="Title*"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <TextBox
                                                value={inquiry}
                                                onValueChange={setInquiry}
                                                type="textarea"
                                                name="inquiry"
                                                label="Inquiry*"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <TextBox
                                                value={email}
                                                onValueChange={setEmail}
                                                type="text"
                                                name="email"
                                                label="Email*"
                                            />
                                        </Col>
                                    </Row>
                                    
                                    <br />
                                    {errorShow &&
                                        <p className="error-message">
                                            {errorMsg}
                                        </p>
                                    }
                                    <VintageBox
                                        right={true}
                                        vintageTwo={true}
                                        position="relative"
                                    >
                                        <Button onClick={e => {
                                            if (valid) {
                                                send();
                                            } else {
                                                setErrorShow(true);
                                            }
                                        }}>Request Quote</Button>
                                    </VintageBox>
                                    {/*                              
                                <LoadingOverlay
                                    active={loading}
                                    spinner
                                    text='Working on it ...'
                                /> */}
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>

        </QuoteWrapper >
    );
};

export default Quote;
